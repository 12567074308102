@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic&display=swap");

:root {
  --color-gray-0: #000000;
  --color-gray-1: #292929;
  --color-gray-9: #a3a3a3;
  --color-gray-10: #ffffff;

  --color-pink: #ff00ff;
  --color-red: #ff0000;
  --color-orange: #ffcc00;
  --color-green: #40ff40;
  --color-cyan: #00ccff;
  --color-purple: #8000ff;
  --color-white: #ffffff;
  --color-black: #000000;

  --font-body: normal 400 16px "Zen Maru Gothic", sans-serif;
  --font-shortcut: normal 400 16px -apple-system, "BlinkMacSystemFont", sans-serif;

  --shadow-bottom: 0 8px 16px rgba(0, 0, 0, 0.25);
}

body {
  font: var(--font-body);
  overscroll-behavior: none;
  overflow: hidden;
}

* {
  margin: 0;
}
