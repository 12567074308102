@import "https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic&display=swap";
:root {
  --color-gray-0: #000;
  --color-gray-1: #292929;
  --color-gray-9: #a3a3a3;
  --color-gray-10: #fff;
  --color-pink: #f0f;
  --color-red: red;
  --color-orange: #fc0;
  --color-green: #40ff40;
  --color-cyan: #0cf;
  --color-purple: #8000ff;
  --color-white: #fff;
  --color-black: #000;
  --font-body: normal 400 16px "Zen Maru Gothic", sans-serif;
  --font-shortcut: normal 400 16px -apple-system, "BlinkMacSystemFont", sans-serif;
  --shadow-bottom: 0 8px 16px #00000040;
}

body {
  font: var(--font-body);
  overscroll-behavior: none;
  overflow: hidden;
}

* {
  margin: 0;
}

.tqfRCW_toast {
  color: var(--color-gray-10);
  box-shadow: var(--shadow-bottom);
  background-color: #000;
  border-radius: 8px;
  padding: 16px 24px;
  position: relative;
}

.tqfRCW_closeButton {
  background: none;
  border: none;
  position: absolute;
  top: 8px;
  right: 8px;
}

.tqfRCW_viewport {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 0;
  list-style: none;
  display: flex;
  position: fixed;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
}

._8V6FsW_container {
  width: 100%;
  height: 100%;
  position: absolute;
}

.D774-q_canvas {
  background-image: url("pattern.c0316c8c.png");
  background-repeat: repeat;
  background-size: 40px;
  width: 100vw;
  height: 100vh;
  position: relative;
}

.D774-q_welcomeWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.D774-q_logoWrapper {
  opacity: .1;
  pointer-events: none;
  position: absolute;
  top: 12px;
  left: 12px;
}

.D774-q_toolBarWrapper {
  z-index: 1;
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.D774-q_menuBarWrapper {
  z-index: 1;
  position: absolute;
  top: 24px;
  right: 24px;
}

.D774-q_infoWrapper {
  opacity: .1;
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.D774-q_info {
  color: var(--color-gray-10);
  text-align: right;
}

.D774-q_textModal {
  background-color: #00000080;
  place-content: center;
  width: 100vw;
  height: 100vh;
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
}

.D774-q_textModal textarea {
  text-align: center;
  color: #fff;
  background: none;
  border: none;
  font-size: 50px;
  font-weight: bold;
}

._2G4Rsa_container {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

._1mgBVG_container {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

._1mgBVG_container[data-tool] {
  cursor: crosshair;
}

._1mgBVG_previewBox {
  pointer-events: none;
  border: 1px solid #d3d3d3;
  position: absolute;
}

._1mgBVG_previewBox:after {
  content: "";
  border: 1px dashed #000;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
}

.YXHMgW_container {
  background-color: var(--color-gray-0);
  box-shadow: var(--shadow-bottom);
  border-radius: 999px;
  align-items: center;
  gap: 16px;
  height: 56px;
  padding: 0 24px;
  display: flex;
}

.YXHMgW_divider {
  background-color: var(--color-gray-1);
  width: 1px;
  height: 16px;
}

.YXHMgW_buttons {
  align-items: center;
  gap: 8px;
  display: flex;
}

.YXHMgW_button {
  cursor: pointer;
  background: none;
  border: none;
  place-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  display: grid;
  position: relative;
}

.YXHMgW_button:hover:not(.YXHMgW_button:disabled) svg path, .YXHMgW_button:hover:not(.YXHMgW_button:disabled) svg rect, .YXHMgW_button[data-active="true"] svg path, .YXHMgW_button[data-active="true"] svg rect {
  fill: var(--color-gray-10);
}

.YXHMgW_button[data-active="true"]:after {
  content: "";
  background-color: var(--color-gray-10);
  border-radius: 2px 2px 0 0;
  width: 24px;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 8px);
}

.YXHMgW_button:disabled {
  opacity: .4;
  cursor: not-allowed;
}

.VJpQWW_trigger {
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 8px;
  place-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  display: grid;
}

.VJpQWW_trigger:hover:not(.VJpQWW_trigger:disabled) {
  background-color: var(--color-gray-1);
}

.VJpQWW_trigger:disabled {
  opacity: .4;
  cursor: not-allowed;
}

.VJpQWW_circle {
  --size: 20px;
  width: var(--size);
  height: var(--size);
  border-radius: 999px;
}

.VJpQWW_circle[data-black="true"] {
  border: 2px solid var(--color-gray-10);
}

.VJpQWW_circle[data-size="xxs"] {
  --size: 4px;
}

.VJpQWW_circle[data-size="xs"] {
  --size: 6px;
}

.VJpQWW_circle[data-size="s"] {
  --size: 8px;
}

.VJpQWW_circle[data-size="m"] {
  --size: 10px;
}

.VJpQWW_circle[data-size="l"] {
  --size: 12px;
}

.VJpQWW_circle[data-size="xl"] {
  --size: 14px;
}

.VJpQWW_circle[data-size="xxl"] {
  --size: 16px;
}

.VJpQWW_content {
  background-color: var(--color-gray-0);
  box-shadow: var(--shadow-bottom);
  z-index: 1;
  border-radius: 8px;
  padding: 16px;
}

.VJpQWW_items {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.VJpQWW_item {
  outline-offset: -2px;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 8px;
  place-items: center;
  width: 32px;
  height: 32px;
  padding: 0;
  display: grid;
}

.VJpQWW_item:hover {
  background-color: var(--color-gray-1);
}

.BPs-lq_trigger {
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 8px;
  place-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  display: grid;
}

.BPs-lq_trigger:hover:not(.BPs-lq_trigger:disabled) {
  background-color: var(--color-gray-1);
}

.BPs-lq_trigger:disabled {
  opacity: .4;
  cursor: not-allowed;
}

.BPs-lq_content {
  background-color: var(--color-gray-0);
  box-shadow: var(--shadow-bottom);
  z-index: 1;
  border-radius: 8px;
  padding: 16px;
}

.BPs-lq_items {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.BPs-lq_item {
  outline-offset: -2px;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 8px;
  grid: auto / 24px 30px;
  place-items: center;
  gap: 4px;
  padding: 4px;
  display: grid;
}

.BPs-lq_item:hover {
  background-color: var(--color-gray-1);
}

.BPs-lq_circle {
  width: var(--size);
  height: var(--size);
  background-color: var(--color-gray-10);
  border-radius: 999px;
}

.BPs-lq_circle[data-size="xxs"] {
  --size: 4px;
}

.BPs-lq_circle[data-size="xs"] {
  --size: 6px;
}

.BPs-lq_circle[data-size="s"] {
  --size: 8px;
}

.BPs-lq_circle[data-size="m"] {
  --size: 10px;
}

.BPs-lq_circle[data-size="l"] {
  --size: 12px;
}

.BPs-lq_circle[data-size="xl"] {
  --size: 14px;
}

.BPs-lq_circle[data-size="xxl"] {
  --size: 16px;
}

.WBjuMG_content {
  color: var(--color-gray-10);
  background-color: var(--color-gray-0);
  box-shadow: var(--shadow-bottom);
  border-radius: 8px;
  align-items: center;
  gap: 16px;
  padding: 12px 16px;
  display: flex;
}

.WBjuMG_shortcut {
  font: var(--font-shortcut);
  color: var(--color-gray-9);
}

.vDGz1G_container {
  align-items: center;
  gap: 16px;
  display: flex;
}

.vDGz1G_historyButtons {
  background-color: var(--color-gray-0);
  box-shadow: var(--shadow-bottom);
  border-radius: 999px;
  grid: auto / auto auto;
  display: grid;
}

.vDGz1G_undo {
  border-radius: 16px 0 0 16px;
  padding: 8px 8px 8px 16px;
}

.vDGz1G_redo {
  border-radius: 0 16px 16px 0;
  padding: 8px 16px 8px 8px;
}

.vDGz1G_menuWrapper {
  background-color: var(--color-gray-0);
  box-shadow: var(--shadow-bottom);
  border-radius: 999px;
}

.vDGz1G_menu {
  border-radius: 999px;
  padding: 8px;
}

.vDGz1G_button {
  cursor: pointer;
  background: none;
  border: none;
  place-items: center;
  display: grid;
}

.vDGz1G_button:hover:not(.vDGz1G_button:disabled) svg path, .vDGz1G_button:hover:not(.vDGz1G_button:disabled) svg rect {
  fill: var(--color-gray-10);
}

.vDGz1G_button:disabled {
  opacity: .4;
  cursor: not-allowed;
}

.h8IyEq_content {
  background-color: var(--color-gray-0);
  border: 1px solid var(--color-gray-1);
  font: var(--font-body);
  box-shadow: var(--shadow-bottom);
  z-index: 1;
  border-radius: 16px;
  padding: 8px;
  font-size: 14px;
}

.h8IyEq_item {
  color: var(--color-gray-10);
  cursor: pointer;
  border-radius: 8px;
  grid: auto / 1fr auto;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 8px 16px;
  display: grid;
}

.h8IyEq_item[data-highlighted] {
  background-color: var(--color-gray-1);
  outline: none;
}

.h8IyEq_shortcut {
  font: var(--font-shortcut);
  color: var(--color-gray-9);
  font-size: 14px;
}

.h8IyEq_separator {
  background-color: var(--color-gray-1);
  height: 1px;
  margin: 8px 0;
}

.gr__XG_container {
  color: var(--color-gray-9);
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 60px 80px;
  display: flex;
  position: relative;
}

.gr__XG_title {
  color: var(--color-gray-10);
  margin: 0;
  font-size: 24px;
  font-weight: 400;
}

.gr__XG_paragraphs {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: flex;
}

.gr__XG_paragraph {
  margin: 0;
}

.gr__XG_shortcut {
  font: var(--font-shortcut);
  color: var(--color-gray-10);
  padding: 0 2px;
}

.gr__XG_selectImage {
  margin: 0;
}

.gr__XG_selectImageLink {
  color: var(--color-gray-10);
}

.gr__XG_selectInput {
  display: none;
}

.gr__XG_corner {
  border: 3px solid var(--color-gray-9);
  opacity: .4;
  pointer-events: none;
  width: 25px;
  height: 25px;
  position: absolute;
}

.gr__XG_topLeft {
  border-bottom: none;
  border-right: none;
  border-top-left-radius: 16px;
  top: 0;
  left: 0;
}

.gr__XG_topRight {
  border-bottom: none;
  border-left: none;
  border-top-right-radius: 16px;
  top: 0;
  right: 0;
}

.gr__XG_bottomRight {
  border-top: none;
  border-left: none;
  border-bottom-right-radius: 16px;
  bottom: 0;
  right: 0;
}

.gr__XG_bottomLeft {
  border-top: none;
  border-right: none;
  border-bottom-left-radius: 16px;
  bottom: 0;
  left: 0;
}

.rNVYSq_container {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  word-break: break-all;
  font-size: 12px;
  position: absolute;
}

.rNVYSq_container:hover, .rNVYSq_container[data-active="true"] {
  outline: 1px solid var(--color-gray-10);
  outline-offset: 0;
}

.rNVYSq_container:hover:after, .rNVYSq_container[data-active="true"]:after {
  content: "";
  pointer-events: none;
  outline: 1px solid var(--color-gray-1);
  outline-offset: -1px;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.rNVYSq_container[data-active="true"] {
  z-index: 1;
}

.pzEMaa_container {
  width: 100%;
  height: 100%;
}

.pzEMaa_cornerHandleWrapper {
  position: absolute;
  transform: translate(-50%, -50%);
}

.pzEMaa_horizontalHandleWrapper {
  width: 100%;
  position: absolute;
  transform: translateY(-50%);
}

.pzEMaa_verticalHandleWrapper {
  height: 100%;
  position: absolute;
  transform: translateX(-50%);
}

.X7hrkW_container {
  background: #fff;
  border: 1px solid #000;
  width: 8px;
  height: 8px;
}

.X7hrkW_container[data-orientation="nwse"] {
  cursor: nwse-resize;
}

.X7hrkW_container[data-orientation="nesw"] {
  cursor: nesw-resize;
}

._7fW-ya_container {
  cursor: ns-resize;
  width: 100%;
  height: 8px;
}

.yW6mTW_container {
  cursor: ew-resize;
  width: 8px;
  height: 100%;
}

._1zzcOq_container {
  width: 100%;
  height: 100%;
  position: relative;
}

._1zzcOq_pointHandleWrapper {
  position: absolute;
  transform: translate(-50%, -50%);
}

._1zzcOq_pointHandle {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 999px;
  width: 8px;
  height: 8px;
}

.fDuRPq_container {
  width: 100%;
  height: 100%;
}

.fDuRPq_verticalHandleWrapper {
  height: 100%;
  position: absolute;
  transform: translateX(-50%);
}

.fDuRPq_cornerHandleWrapper {
  position: absolute;
  transform: translate(-50%, -50%);
}

.fDuRPq_overlay {
  background-color: var(--color-gray-0);
  opacity: .4;
  z-index: 1;
  position: fixed;
  inset: 0;
}

.fDuRPq_content {
  border: 1px solid var(--color-gray-1);
  background-color: var(--color-gray-0);
  box-shadow: var(--shadow-bottom);
  z-index: 1;
  border-radius: 16px;
  width: 400px;
  padding: 16px 24px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fDuRPq_form {
  grid: 1fr auto / 1fr;
  gap: 16px;
  display: grid;
}

.fDuRPq_textareaWrapper {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.fDuRPq_newLineHint {
  text-align: end;
  color: var(--color-gray-9);
  font-size: 12px;
}

.fDuRPq_shortcut {
  font: var(--font-shortcut);
  color: var(--color-gray-10);
  font-size: 12px;
}

.fDuRPq_textarea {
  background-color: var(--color-gray-0);
  border: 1px solid var(--color-gray-9);
  color: var(--color-gray-10);
  border-radius: 8px;
  height: 100px;
  padding: 16px;
  font-size: 16px;
}

.fDuRPq_textarea::placeholder {
  color: var(--color-gray-9);
}

.fDuRPq_footer {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.fDuRPq_alignmentButtons {
  align-items: center;
  display: flex;
}

.fDuRPq_alignmentButton {
  cursor: pointer;
  background: none;
  border: none;
  place-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  display: grid;
}

.fDuRPq_alignmentButton:hover path {
  fill: var(--color-gray-10);
}

.fDuRPq_alignmentButton[data-state="on"] {
  background-color: var(--color-gray-1);
}

.fDuRPq_alignmentButton[data-state="on"] path {
  fill: var(--color-gray-10);
}

.fDuRPq_alignmentButton:first-of-type {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.fDuRPq_alignmentButton:last-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.fDuRPq_buttons {
  grid: auto / auto auto;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  display: grid;
}

.fDuRPq_button {
  border: 1px solid var(--color-gray-9);
  font: var(--font-body);
  color: var(--color-gray-10);
  cursor: pointer;
  background: none;
  border-radius: 4px;
  min-width: 80px;
  padding: 8px 16px;
}

.fDuRPq_button:hover {
  background-color: var(--color-gray-1);
}

.YrjMgq_container {
  width: 100%;
  height: 100%;
  position: relative;
}

.YrjMgq_pointHandle {
  cursor: crosshair;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 999px;
  width: 8px;
  height: 8px;
  position: absolute;
  transform: translate(-50%, -50%);
}

.jUG6aG_container {
  width: 100%;
  height: 100%;
}

.jUG6aG_cornerHandleWrapper {
  position: absolute;
  transform: translate(-50%, -50%);
}

.jUG6aG_horizontalHandleWrapper {
  width: 100%;
  position: absolute;
  transform: translateY(-50%);
}

.jUG6aG_verticalHandleWrapper {
  height: 100%;
  position: absolute;
  transform: translateX(-50%);
}

/*# sourceMappingURL=index.b46aa490.css.map */
